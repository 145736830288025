import * as React from 'react';
import fetch from 'isomorphic-unfetch';
import { captureException } from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import Draw from 'ol/interaction/Draw';
import GeoJSON, { GeoJSONPoint } from 'ol/format/GeoJSON';
import { Icon, Style } from 'ol/style';
import { fetchOrCreateUserDemographics } from 'Client/services/demographics';
import {
  useProject,
  useAnalytics,
  MixpanelEventTypes,
  usePermissions,
  useUser,
  useMap,
} from 'Client/utils/hooks';
import { ContributionType } from 'Shared/types/contribution';
import { MapProjection } from 'Shared/types/map';
import {
  getLocalItem,
  CONTRIBUTION_SESSION_ITEM,
} from 'Client/utils/localstorage';
import { getContributionStatus } from 'Client/services/contributions';
import { createContribution } from 'Client/services/contributions/createContribution';
import { Permissions } from 'Client/constants/permissions';
import { PIN_SVG_ICON, CURSOR_PIN_SVG_ICON } from 'Client/constants/map';
import { useUtils } from 'Client/utils/hooks/useUtils';
import { AddFeatureButton } from './AddFeatureButton';
import { DeletePageButton } from './DeletePageButton';
import { EditMapButton } from './EditMapButton';
import { HaveYourSayButton } from './HaveYourSayButton';

interface MapActionButtonsProps {
  isEditMode?: boolean;
}

export const MapActionButtons: React.FC<MapActionButtonsProps> = ({
  isEditMode,
}) => {
  const {
    state: {
      uniqueId,
      proposal,
      mode,
      contributionFlow,
      xyz,
      draftContributionLayer,
    },
    dispatch,
  } = useMap();
  const { apiToken } = useUtils();
  const { can } = usePermissions();
  const { i18n } = useTranslation();
  const { user } = useUser();
  const { trackEvent } = useAnalytics();
  const project = useProject();
  const router = useRouter();
  const currentContributionId = React.useRef(null);
  /* We don't want to trigger gaming check when user reaches thanks page at the moment
   * as it takes some good seconds for the check we're removing it for now
   */
  const ignoreGaming = true;

  React.useEffect(() => {
    if (!contributionFlow) {
      currentContributionId.current = null;
    }
  }, [contributionFlow]);

  const createDraftContribution = async (feature: GeoJSONPoint) => {
    try {
      const storedUserId = getLocalItem(CONTRIBUTION_SESSION_ITEM);
      const { demographics } = await fetchOrCreateUserDemographics({
        user,
        userId: storedUserId,
        projectName: project.id,
        language: i18n.language,
      });
      const data = {
        map: true,
        location: feature,
        surveySlug: proposal.slug,
        pageId: proposal.pageId,
        uniqueId,
        type: ContributionType.COMMENT,
        draft: true,
        userId: user?._id || storedUserId,
        demographicsId: user ? demographics?._id : null,
        language: i18n.language,
        status: getContributionStatus({
          user,
          storedUserId,
        }),
      };
      const createdContribution = await createContribution(
        data,
        ignoreGaming,
        apiToken
      );
      const contributionId = createdContribution._id;
      if (project?.features?.trackContributionFlow) {
        trackEvent(MixpanelEventTypes.TRACK_CONTRIBUTION_FLOW, {
          fileName:
            'src/client/components/molecules/MapActionButtons/index.tsx',
          functionName: 'createDraftContribution',
          database: 'acorn',
          fieldToBeUpdated: Object.keys(data),
          gaudiUpdate: null,
          acornUpdate: data,
          userId: data?.userId,
          demographicsId: data?.demographicsId,
          contributionId: contributionId,
        });
      }

      if (!contributionId) {
        throw new Error('No contribution id');
      }
      currentContributionId.current = contributionId;

      trackEvent(MixpanelEventTypes.PIN_DROPPED, {
        path: router.asPath,
        contributionId,
        coordinates: {
          longitude: feature.coordinates[0],
          latitude: feature.coordinates[1],
        },
      });

      dispatch({
        type: 'ADD_COMMENT',
        payload: {
          geometry: { coordinates: feature.coordinates },
          contributionId,
        },
      });
    } catch (err) {
      xyz.mapview.node.style.cursor = 'crosshair';
      captureException(
        `Error in createDraftContribution @ MapActionButtons/index.tsx: ${err.toString()}`
      );
    }
  };

  const updateExistentDraftContribution = async (feature: GeoJSONPoint) => {
    const _contributionId = currentContributionId.current;
    try {
      await fetch(`/api/contributions/${_contributionId}`, {
        method: 'PATCH',
        body: JSON.stringify({
          location: feature,
        }),
      });

      dispatch({
        type: 'ADD_COMMENT',
        payload: {
          geometry: { coordinates: feature.coordinates },
          contributionId: _contributionId,
        },
      });

      if (project?.features?.trackContributionFlow) {
        const storedUserId = getLocalItem(CONTRIBUTION_SESSION_ITEM);
        const { demographics } = await fetchOrCreateUserDemographics({
          user,
          userId: storedUserId,
          projectName: project.id,
          language: i18n.language,
        });
        trackEvent(MixpanelEventTypes.TRACK_CONTRIBUTION_FLOW, {
          fileName:
            'src/client/components/molecules/MapActionButtons/index.tsx',
          functionName: 'updateExistentDraftContribution',
          database: 'acorn',
          fieldToBeUpdated: ['location'],
          gaudiUpdate: null,
          acornUpdate: {
            location: feature.coordinates,
          },
          userId: user?._id || storedUserId,
          demographicsId: user ? demographics?._id : null,
          contributionId: _contributionId,
        });
      }
      trackEvent(MixpanelEventTypes.REALLOCATED_CONTRIBUTION_PIN, {
        path: router.asPath,
      });
    } catch (err) {
      captureException(err);
    }
  };

  const onEnd = async (event) => {
    event.feature.setStyle(
      new Style({
        image: new Icon({
          src: PIN_SVG_ICON,
          anchor: [0.5, 1],
          scale: 2,
        }),
      })
    );

    const feature = new GeoJSON().writeGeometryObject(
      event.feature.getGeometry(),
      {
        dataProjection: MapProjection.WORLD_GEODETIC_GPS,
        featureProjection: MapProjection.WEB_MERCATOR,
      }
    ) as GeoJSONPoint;

    draftContributionLayer.getSource().clear();

    draftContributionLayer.getSource().addFeature(event.feature);

    if (currentContributionId?.current) {
      updateExistentDraftContribution(feature);
    } else {
      xyz.mapview.node.style.cursor = 'default';
      createDraftContribution(feature);
    }
  };

  const startContributionFlow = () => {
    trackEvent(MixpanelEventTypes.ADDING_COMMENT, {
      path: router.asPath,
    });

    const draw = new Draw({
      source: xyz.layers.list.Contributions.source,
      type: 'Point',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      style: new (window as any).ol.style.Style({}),
    });

    xyz.map.addInteraction(draw);

    draw.on('drawend', onEnd);

    xyz.mapview.node.style.cursor = `url(${CURSOR_PIN_SVG_ICON}) 18 35, pointer`;
  };

  const cancelDrawing = () => {
    if (xyz?.mapview?.interaction?.draw) {
      xyz.map.getInteractions().forEach((interaction) => {
        if (interaction instanceof Draw) {
          interaction.setActive(false);
        }
      });

      xyz.mapview.node.style.cursor = 'default';

      dispatch({ type: 'CLEAR_CONTRIBUTION_FLOW' });

      dispatch({
        type: 'SET_CONTRIBUTION_FLOW_STARTED',
        payload: false,
      });

      trackEvent(MixpanelEventTypes.COMMENT_CANCELLED, {
        path: router.asPath,
        contributionId: undefined,
        numOfAnswers: 0,
      });
    }
  };

  React.useEffect(() => {
    if (xyz && router?.query?.haveYourSay && proposal) {
      dispatch({
        type: 'SET_CONTRIBUTION_FLOW_STARTED',
        payload: true,
      });
      startContributionFlow();
    }
  }, [xyz, proposal]);

  return (
    <>
      {!isEditMode && mode !== '3d' && proposal && (
        <HaveYourSayButton
          proposal={proposal}
          onClick={startContributionFlow}
          onCancelDrawing={cancelDrawing}
        />
      )}
      {!isEditMode && can(Permissions.MAP_EDIT_MODE) && <EditMapButton />}
      {isEditMode && can(Permissions.MAP_EDIT_MODE) && (
        <>
          <AddFeatureButton />
          <DeletePageButton />
        </>
      )}
    </>
  );
};
